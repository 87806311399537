#schedule-form {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1000;

    > .form {
        background-color: white;
        width: 50%;
        min-width: 250px;
        height: 50%;
        padding: 10px;
        margin: 10% auto 0;
        filter: drop-shadow(16px 16px 20px rgba(0,0,0,0.2));
    }
}

html.schedule-form-active {
    .t-pattern {
        filter: blur(3px);
    }
    #schedule-form {display: block;}
}
