.a-intro > .slideshow-nav {
    > div {
        display: inline-block;
        cursor: pointer;
        width: 38px;
        height: 26px;
        background-image: url(../imgs/a-left.png);
        margin: -2px 23px 2px 0;
        transition: all 0.3s;
        &.owl-next{
        	background-image: url(../imgs/a-right.png);
        }
		&:hover{
			margin: 2px 23px -2px 0;
		}        
    }
}
