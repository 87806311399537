@mixin small-menu {
  @media (max-width: 1312px) {
    @content;
  }
}

@media screen and (max-width: 1600px) {
	.a-intro{
		.owl-carousel{
			h1{
				font-size: 65px;
				max-width: 1150px;
				line-height: 60px
			}	
			.smh{
				max-width: 1000px;
			}			
		}
	}
	.homeb .max-width .a-intro{
		padding-top: 30px;
	}
	.homeb .max-width .a-scroll{
		margin: 0 0 50px 0;
		padding: 0;
	}
}


@media screen and (max-width: 1312px) {
	.about{
		background: #fff;
	}	
	.internal{
		.max-width{
			padding: 0;
		}
		.logo{
			margin: 0 0 50px 50px;
		}
		.left{
			width: 100%;
			flex: 1 1 auto;
		}
		.right{
			width: 100%;
			min-width: 100%;
			flex: 1 1 auto;
			.content{
				padding: 50px;
				max-width: 100%;
				h1{
					margin-top: 0px;
				}
			}
		}
	}
	.about .left{
		background: #fff url(../imgs/bg-horz-team.png) no-repeat center center;
		background-size: cover;
	}
	.contact .left{
		background: #fff url(../imgs/bg-horz-3fam.png) no-repeat right top;
		background-size: cover;		
	}
	.why .left{
		background: #fff url(../imgs/bg-horz-bridge.png) no-repeat center center;
		background-size: cover;		
	}	
	.services .left{
		background: #fff url(../imgs/bg-horz-fishing.png) no-repeat center center;
		background-size: cover;		
	}	
	.approach .left{
		background: #fff url(../imgs/bg-horz-woods.png) no-repeat center center;
		background-size: cover;		
	}		
	.resources .left{
		background: #fff url(../imgs/bg-horz-farm.png) no-repeat center center;
		background-size: cover;		
	}	
}

@media screen and (max-width: 1205px) {
	.tri{
		h2{
			font-size: 30px;
			line-height: 36px;
			margin-bottom: 10px;
		}
		p{
			font-size: 20px;
			line-height: 26px;
		}
		h3{
			font-size: 30px;
			line-height: 36px;
		}
	}
	#schedule-form > .form {
		padding: 30px;
	}		
}

@media screen and (max-width: 1040px) {
	.a-intro .owl-carousel {
		h1{
			font-size: 60px;
			line-height: 60px;
			margin-bottom: 20px;
		}
		p{
			margin-bottom: 20px;
		}
	}
	.light-cover{
		.set{
			padding-top: 50px;
			max-width: 500px;
			margin: 0 auto;
		}
		.set-item{
			flex: 1 1 auto;
			width: 100%;
			text-align: left;
			h4{
				padding-bottom: 20px;
			}
			.button{
				display: block;
				max-width: 250px;
				margin: 0 auto;
				text-align: center;
				float: none;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.options{
		padding: 20px 0;
		background: #fff;
		.set {
            flex-direction: column;
            .set-item{
    			width: 100%;
    			max-width: 100%;
    			min-width: 100%;
    			text-align: center;
    		}
        }
	}
	.highlight{
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		margin: 0;
	}
	.tri{
		background: #fff;
		.space{
			height: auto;
			display: block;		
			.mobile{
				display: block;
				width: 100%;
			}
		}
	}	
	.options{
		padding: 30px 0 30px 0;
		.set{
			display: block;
			.set-item{
				margin: 0 0 20px 0;
			}
		}
	}	
}

@media screen and (max-width: 860px) {
	.highlight{
		.set-item{
			width: 100%;
			flex: 1 1 auto;
		}
	}
	.email{
		h2{
			font-size: 30px;
			line-height: 36px;
		}
	}
	.logo{
		width: 150px;
	}
}

@media screen and (max-width: 700px) {
	.about .internal .right .content .profiles{
		li{
			flex-wrap: wrap;
		}
	}
}

@media screen and (max-width: 620px) {
	.a-intro .owl-carousel {
		h1{
			font-size: 25px;
			line-height: 30px;
		}
		.button{
			background: $second-color;
			color: #fff;
			display: block;
			margin: 0 auto;
			max-width: 250px;
			padding: 15px 52px;
			text-align: center;
			font-size: 18px;
		}
		p{
			font-size: 20px;
			line-height: 27px;
		}
	}
	.highlight{
		padding: 30px 20px;
	}
	#schedule-form > .form{
		width: 95%;
		padding: 30px;
	}
	.internal{
		.right .content{
			h1{
				font-size: 50px;
				line-height: 57px;

			}
			h2{
				font-size: 30px;
				line-height: 36px;
			}
		}
		.left{
			height: 300px;
		}
	}
	.homeb .max-width .a-scroll{
		margin: 50px 0;
	}
	.options{
		padding: 30px 0 30px 0;
		.set{
			display: block;
			.set-item{
				margin: 0 0 20px 0;
			}
		}
	}
	.email{
		min-height: auto;
		padding: 30px 0;
	}	
	.light-cover .set-item .button, .email button{
		font-size: 12px;
		padding: 17px 25px;		
		letter-spacing: .1em;
		font-family: $sansB;
		display: inline-block;
		width: auto;
		margin: 0 auto;		
	}
	.about .internal .right .content .profiles .button{
		font-size: 12px;
		padding: 14px 20px;	
		letter-spacing: .1em;	
	}
	.about .left{
		background: #fff url(../imgs/bg-team-flip.png) no-repeat center center;
		background-size: cover;
	}
	.contact .left{
		background: #fff url(../imgs/bg-3fam-flip.png) no-repeat right center;
		background-size: cover;		
	}
	.why .left{
		background: #fff url(../imgs/bg-bridge.png) no-repeat center center;
		background-size: cover;		
	}	
	.services .left{
		background: #fff url(../imgs/bg-fishing.png) no-repeat center center;
		background-size: cover;		
	}	
	.approach .left{
		background: #fff url(../imgs/bg-woods.png) no-repeat center center;
		background-size: cover;		
	}		
	.resources .left{
		background: #fff url(../imgs/bg-farm.png) no-repeat center center;
		background-size: cover;		
	}
}


@media screen and (max-width: 588px) {
	.email{
		form{
			input{
				margin-bottom: 10px;
				width: 100%;
			}
		}
	}
	html.sticky-active .menu .stick-logo{
		margin-top: 10px;
	}
	.menu > .a-head-small{
		top: 10px;
	}
	html.sticky-active .menu .cta{
		margin: 65px -50px 0 -50px;
	}
	html.sticky-active .menu {
    	max-height: 120px;
	}
	html.sticky-active .menu > .dropdown-menu {
    	margin-top: 12px;
    }
    .light-cover .set .linkedin{
    	background: #009AB9 url(../imgs/linkedin-m.png) no-repeat center center;
    	width: 100%;
    	&:hover{
    		background: #009AB9 url(../imgs/linkedin-m.png) no-repeat center center;
    		width: 100%;
    		opacity:.5;
 	   	}
    }
}

@media screen and (max-width: 365px) {
	.about .internal .right .content .profiles li .step img{
		min-width: 100%;
	}
	.about .internal .right .content .profiles li .step{
		min-width: 100%;
	}
	.internal .logo {
    	margin: 0 0 50px 10px;	
    }
    .a-head-small{
		right: 10px !important;
		margin-right: 20px;
    }
    .homeb .logo{
        margin-left: -40px;
    }
}