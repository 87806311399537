@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,200italic,300,300italic,400italic,600,600italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,700italic,900,900italic,300italic,400italic,300);
@import url(https://fonts.googleapis.com/css?family=Happy+Monkey);

$prime-color:#3a85a8;
$second-color:#f9692c;
$dark-color: #444444;
$sans: "Source Sans Pro", "Trebuchet MS", Helvetica, sans-serif;
$sansB: "Lato", "Trebuchet MS", Helvetica, sans-serif;
$fw-bold: 700;
$fw-norm: 400;
$fw-light: 300;

html {
	height: 100%;
	min-height: 100%;
 }
*::selection{
	background: $prime-color;
	color: #fff;
}
body{
	min-height: 100%;
	font-family: $sans;
	overflow-x: hidden;
	&.home{
		background: url(../imgs/bg-top.png) no-repeat center center fixed;
		background-size: cover;
		background-position: top center;
	}
}
.t-pattern{
	background: url(../imgs/bg-lines.png) no-repeat top center;
	background-size: 100%;
	height: auto;
	min-height: 200vmin;
	z-index: 2;
	width: 100%;
	position: absolute;
	top: 0;
}
.max-width{
	width: 100%;
	max-width: 1722px;
	margin: 0 auto;
	padding: 0 50px;
}

.homeb{
	width: 100%;
	.max-width{
		height: auto;
		min-height: 100vmin;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-wrap: nowrap;
		justify-content: center;
		align-content: stretch;
		.set-top{
			align-self: flex-start;
			flex: 1 1 auto;
			width: 100%;
		}
		.a-intro{
			align-self: auto;
			flex: 1 1 auto;
			width: 100%;
			padding-top: 100px;
		}
		.a-scroll{
			align-self: center;
			flex: 1 1 auto;
			color: #fff;
			text-decoration: none;
			font-size: 	20px;
			text-transform: uppercase;
			font-family: $sans;
			padding: 50px 0;
			text-align: center;
			letter-spacing: .1em;
            &::after{
                display: block;
                content: "";
                width: 126px;
                height: 30px;
                transition: all 0.3s;
                margin-top: 5px;
                background: url('../imgs/scroll.png') no-repeat top center;
            }
            &:hover::after{
            	background: url('../imgs/scroll.png') no-repeat bottom center;
			}
		}
	}
}
.logo{
	float: left;
}
.hide{
	display: none;
}

.menu{
	text-align: right;
	.cta {
		text-align: right;
		margin-bottom: 50px;
		transition: all 0.5s;
		a{
			display: inline-block;
			background: rgba(#fff, .3);
			padding: 17px 25px;
			font-size: 16px;
			text-transform: uppercase;
			color: #fff;
			font-family: $sansB;
			font-weight: $fw-light;
			text-decoration: none;
			transition: all 0.3s;
			letter-spacing: .1em;
			&:last-of-type{
				border-left: 1px solid $prime-color;
				color: #fff;
				background: $second-color;
			}
			&:hover{
				color: #fff;
				background: $prime-color;
			}
		}
	}
	.a-head{
		clear: right;
		a{
			color: #fff;
			text-decoration: none;
			font-family: $sansB;
			font-weight: $fw-light;
			font-size: 18px;
			text-transform: uppercase;
			margin-left: 40px;
			padding: 0 0 10px 0;
			transition: all 0.3s;
			letter-spacing: .1em;
			&:hover{
				border-bottom: 1px solid #fff;
			}
		}	
	}
}
.a-intro{
	.slideshow-nav{
		color: #fff;
		font-size: 30px;
	}
	.owl-carousel{
		h1{
			color: #fff;
			font-size: 90px;
			text-transform: uppercase;
			font-family: $sans;
			margin: 20px 0 20px 0;
			line-height: 86px;
		}
		.smh{
			max-width: 1200px;
		}
		p{
			color: #fff;
			font-size: 29px;
			max-width: 600px;
			margin-bottom: 52px;
			font-family: $sans;
			line-height: 32px;
		}
		.button{
			color: #fff;
			font-size: 26px;
			background: $second-color;
			padding: 15px 75px;
			text-transform: uppercase;
			display: inline-block;
			font-family: $sansB;
			text-decoration: none;
			transition: all 0.3s;
			font-weight: $fw-light;
			letter-spacing: .1em;
			&:hover{
				background: #fff;
				color: $second-color;
			}
		}
	}
}
.tri{
	background: #0083A0 url(../imgs/bg-middle.png) no-repeat bottom right;
	background-size: cover;
	height: auto;
	min-height: 120vmin;
	z-index: 1;
	width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
	h2{
		color: $second-color;
		font-weight: $fw-norm;
		font-family: $sansB;
		text-transform: uppercase;
		font-size: 32px;
		margin: 0 0 20px 0;
	}
	p{
		font-size: 26px;
		color: $dark-color;
		line-height: 32px;
		font-weight: $fw-light;
	}
}
.set{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}
.set-item{
	flex: 1 1 250px;
	padding: 20px;
	align-self: auto;
}
.options{
	background: rgba( #fff, .75 );
	padding: 120px 0;
	width: 100%;
	align-items: flex-start;
	.set {
	    align-items: flex-start;
	    max-width: 1622px;
	    margin: 0 auto;
	    .set-item{
			max-width: 416px;
		}
	}
}
.space{
	height: 100vmin;
	display: block;
	.mobile{
		display: none;
	}
}
.highlight{
	background: rgba( #fff, .78 );
	width: 100%;
	max-width: 1620px;
	margin: 70px;
	padding: 70px;
	h3{
		color: $prime-color;
		font-size: 46px;
		font-family: $sansB;
		font-weight: $fw-norm;
		line-height: 54px;
		&::before{
			content: "";
			border-top: 7px solid $second-color;
			width: 108px;
			display: block;
			margin: 0 0 32px 0;
		}
	}
	p{
		font-size: 26px;
		color: $dark-color;
		line-height: 36px;
		span{
			background: $prime-color;
			color: #fff;
			padding: 5px;
		}
	}
}
.email{
	background: #4D86A1 url(../imgs/bg-tri-top.png) no-repeat bottom center;
	background-size: 100%;
	height: auto;
	min-height: 120px;
	z-index: 1;
	width: 100%;
	padding: 143px 0px;
	text-align: center;
	h2{
		color: #fff;
		font-size: 40px;
		font-family: $sansB;
		font-weight: $fw-norm;
		line-height: 48px;
		max-width: 810px;
		margin: 0 auto;
		padding: 29px 20px;
		background: url(../imgs/diag-right.png) no-repeat center center;
	}
	form{
		display: block;
		max-width: 926px;
		margin: 50px auto 0px auto;
		padding: 0 20px;
	}
	button{
		background: #88b2c6;
		border: none;
		color: #fff;
		text-transform: uppercase;
		padding: 17px 60px;
		display: inline-block;
		letter-spacing: 1px;
		font-size: 20px;
		font-family: $sans;
		transition: all 0.8s;
		text-spacing: .1em;
		font-weight: $fw-light;
		&:hover{
			background: $second-color;
		}
	}
	input{
		background: transparent;
		border: none;
		border-bottom: 1px solid #fff;
		width: 60%;
		display: inline-block;
		color: #fff;
		font-size: 20px;
		padding: 10px 0 16px 0;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin: 0 20px 0 0;
		transition: all 0.8s;
		&:focus{
			color: #fff;
			outline: 0px;
		}
		&::-webkit-input-placeholder {
   			color: #fff;
		}
	}
	[placeholder]:focus::-webkit-input-placeholder {
		color: transparent;
	}	
}
.light-cover{
	background: #fff url(../imgs/bg-tri-bottom.png) no-repeat top center;
	background-size: 100%;
	height: auto;
	min-height: 100vmin;
	z-index: 1;
	width: 100%;
	.set{
		max-width: 1200px;
		margin: 0 auto;
		padding-top: 110px;
		h4{
			color: $prime-color;
			font-size: 22px;
			padding-bottom: 42px;
		}
		p{
			color: $dark-color;
			font-size: 18px;
			margin-bottom: 14px;
			text-align: left;
		}
		a{
			display: inline-block;
			float: left;
			clear: both;
			color: $dark-color;
			text-decoration: none;
			text-transform: uppercase;
			transition: all 0.8s;
			margin-bottom: 20px;
			border-bottom: 1px solid #fff;
			font-size: 16px;
			&:hover{
				border-bottom: 1px solid $dark-color;
				width: auto;
			}
		}
		.linkedin{
			display: block;
			width: 51px;
			height: 51px;
			border: none;
			transition: none;
			background: #fff url(../imgs/linkedin.png) no-repeat top;
			span{
				display: none;
			}
			&:hover{
				width: 51px;
				border-bottom: none;
				background: #fff url(../imgs/linkedin.png) no-repeat bottom;
			}
		}
		.button{
			color: #fff;
			background: $second-color;
			padding: 15px 55px;
			text-align: center;
			display: inline-block;
			text-decoration: none;
			font-size: 18px;
			text-transform: uppercase;
			transition: all 0.8s;
			margin: 26px 0 20px 0;
			text-spacing: .1em;
			font-weight: $fw-light;
			letter-spacing: .1em;
			&:hover{
				background: $prime-color;
				color: #fff;
				border: none;
			}
		}
	}
	.sub-area{
		margin-top: -50px;
		p{
			font-size: 15px;
			line-height: 23px;
			color: #a7a7a7;
			font-weight: $fw-light;
		}
		&::after{
			border-bottom: 1px solid $dark-color;
			content: "";
			display: block;
			max-width: 1200px;
			margin: 0 auto;
		}
	}
	.icon{
		margin: 50px auto 0 auto;
		display: block;
		padding-bottom: 100px;
	}
}
.copy{
	text-align: center;
	color: #a7a7a7;
	margin: 20px 10px 0 10px;
	font-size: 15px;
    line-height: 23px;
    font-weight: $fw-light;
    .grey{
    	color: #a7a7a7;
    	font-weight: $fw-light;
    	transition: all 0.3s;
    	&:hover{
    		color: $second-color;
    	}
    }
}

.internal{
	width: 100%;
	height: auto;
	min-height: 100vmin;
	.right{
		background: #fff;
		height: auto;
		min-width: 945px;
		min-height: 100vh;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		p{
			font-size: 18px;
			margin: 0 0 20px 0;
		}		
		p.title{
			color: $second-color;
			font-size: 22px;
			margin: 50px 0 10px 0;
			text-transform: capitalize;
		}		
		.a-head {
				a{
				color: $dark-color;
				&:hover{
					border-bottom: 1px solid $dark-color;
				}
				&.current{
					border-bottom: 1px solid $dark-color;
				}	
			}
		}
		.cta{
			a{
				background: $prime-color;
				color: #fff;
				transition: all 0.3s;				
				&:hover{
					background: rgba($prime-color, .7);
				}							
				&:last-of-type{
					background: $second-color;
					border-left: 1px solid $prime-color;				
					&:hover{
						background: rgba($prime-color, .7);
					}								
				}	
			}
		}
		.content{
			padding-left: 83px;
			margin-bottom: 50px;
			h1{
				text-transform: uppercase;
				color: $prime-color;
				font-size: 90px;
				margin: 150px 0 30px 0;

			}
			h2{
				font-size: 46px;
				color: $dark-color;
				line-height: 54px;
				margin-bottom: 50px;
			}
			p{
				line-height: 29px;
			}
			a{
				color: $prime-color;
				transition: all 0.3s;
				&:hover{
					color: $second-color;
				}
			}			
		}
		ul.list{
			list-style: disc;
			margin: 0px 0 20px 50px;
			li{
				font-size: 18px;
				line-height: 29px;
			}
		}		
	}
	.set-item{
		padding: 0;
	}
}

.about{
	background: #fff url(../imgs/bg-team.png) no-repeat left center fixed;
	.internal .right .content .profiles{
		display: block;
		li{
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-content: stretch;
			align-items: flex-start;
			.step{
				flex: 0 1 auto;
				min-width: 305px;
				img{
					width: 100%;
					min-width: auto;
					max-width: 265px;
					margin: 55px 50px 0 0;
				}
			}
		}
		h2{
			color: $second-color;
			font-size: 22px;
			margin: 55px 0 15px 0;
			line-height: 27px;
		}
		p{
			margin: 0 0 30px 0;
			font-size: 18px;
			line-height: 25px;
		}
		p.title{
			margin: 0 0 5px 0;
			font-weight: $fw-bold;
			color: $dark-color;
		}
		p.s-title{
			color: $dark-color;
			font-weight: $fw-light;
		}
		.button{
			background-color: $prime-color;
			color: #fff;
			padding: 17px 25px;
		    display: inline-block;
		    text-align: center;
		    margin: 20px 0 0 0;
			text-decoration: none;
			text-spacing: .1em;
			font-size: 16px;
			letter-spacing: .1em;
			font-weight: $fw-light;
			text-transform: uppercase;
			font-family: $sansB;
			&:hover{
				background: rgba($prime-color, .7);
			}
		}
	}
}
.contact{
	background: #fff url(../imgs/bg-3fam.png) no-repeat left center fixed;
}

.services{
	background: #fff url(../imgs/bg-fishing.png) no-repeat left center fixed;
}

.why{
	background: #fff url(../imgs/bg-bridge.png) no-repeat left center fixed;
}

.approach{
	background: #fff url(../imgs/bg-woods.png) no-repeat left center fixed;
}

.resources{
	background: #fff url(../imgs/bg-farm.png) no-repeat left center fixed;
}

.contact {
	form.wpcf7-form {
	    vertical-align: top;
	    max-width: 500px;
	    p.message{
	    	padding: 30px 0 10px 0;
	    	margin-bottom: 0px;
	    	clear: both;
	    	text-transform: uppercase;
	    	color: $dark-color;
	    	font-size: 20px;
	    }
		input{
			background: transparent;
			border: none;
			border-bottom: 1px solid $dark-color;
			width: 100%;
			max-width: 200px;
			display: inline-block;
			color: $prime-color;
			font-size: 20px;
			padding: 20px 0 10px 0;
			text-transform: uppercase;
			float: left;
			letter-spacing: 1px;
			margin: 0px 20px 0 0;
			transition: all 0.8s;
			&:focus{
				outline: 0px;
			}
			&::-webkit-input-placeholder {
	   			color: $dark-color;
			}		
		}
		textarea{
			background: transparent;
			border: 1px solid $dark-color;
			width: 100%;
			max-width: 420px;
			display: inline-block;
			color: $prime-color;
			font-size: 18px;
			padding: 10px;
			float: left;
			font-weight: $fw-light;
			letter-spacing: 1px;
			margin: 0px 100px 0 0;
			transition: all 0.8s;
			&:focus{
				outline: 0px;
			}
			&::-webkit-input-placeholder {
	   			color: $dark-color;
			}
		}
		input[type=submit]{
			clear: both;
			background: $second-color;
			margin: 50px 0 20px 0;
			color: #fff;
			font-weight: $fw-light;
			border: none;
			padding: 10px 20px;
			font-size: 18px;
			transition: all 0.3s;
			&:hover{
				background: $prime-color;
			}
		}
		[placeholder]:focus::-webkit-input-placeholder {
			color: transparent;
		}			
	}	
}

#schedule-form{
	overflow: auto;
    max-height: 100vh;	
	.close{
		text-align: right;
		a{
			text-decoration: none;
			font-size: 30px;
			color: $dark-color;
			transition: all 0.3s;
			padding: 20px;
			display: inline-block;
			font-family: 'Happy Monkey';
			&:hover{
				color: $second-color;
			}
		}
	}
	form.wpcf7-form {
	    vertical-align: top;
	    width: 100%;
	    max-width: 500px;
	    margin: 0 auto;
	    h4{
	    	color: $prime-color;
	    	font-size: 32px;
	    	clear: both;
	    	line-height: 38px;
	    	text-transform: uppercase;
	    	margin-bottom: 20px;
	    }
	    p{
	    	clear: both;
	    }
	    p.when{
	    	padding: 40px 0 0px 0;
	    	margin-bottom: 0px;
	    	clear: both;
	    	color: $dark-color;
	    	font-size: 18px;
	    }
		input{
			background: transparent;
			border: none;
			border-bottom: 1px solid $dark-color;
			width: 100%;
			max-width: 200px;
			display: inline-block;
			color: $prime-color;
			font-size: 20px;
			padding: 20px 0 10px 0;
			text-transform: uppercase;
			float: left;
			letter-spacing: 1px;
			margin: 0px 20px 0 0;
			&:focus{
				outline: 0px;
			}
			&::-webkit-input-placeholder {
	   			color: $dark-color;
			}
		}
		textarea{
			background: transparent;
			border: 1px solid $dark-color;
			width: 100%;
			max-width: 420px;
			display: inline-block;
			color: $prime-color;
			font-size: 18px;
			padding: 10px;
			float: left;
			font-weight: $fw-light;
			letter-spacing: 1px;
			margin: 0px 20px 0 0;
			&:focus{
				outline: 0px;
			}
			&::-webkit-input-placeholder {
	   			color: $dark-color;
			}
		}
		input[type=submit]{
			clear: both;
			background: $second-color;
			margin: 20px auto 40px auto;
			color: #fff;
			font-weight: $fw-light;
			border: none;
			padding: 10px 20px;
			font-size: 18px;
			transition: all 0.3s;
			display: block;
   			float: none;
			&:hover{
				background: $prime-color;
			}
		}
		[placeholder]:focus::-webkit-input-placeholder {
			color: transparent;
		}			
		p.full{
	   		input{
				width: 100%;
				max-width: 500px;
				margin: 20px 0 0 0;
				padding: 0 45px 10px 0;
	   		} 	
	    }
	}		
}
span.wpcf7-not-valid-tip {
    clear: both;
    margin: 10px 0 0 0;
    padding: 20px 0 0 0;
    float: none;
    color: $second-color !important;
}
div.wpcf7-validation-errors{
	display: block;
    float: none;
    clear: both;
    font-size: 20px;
    padding: 20px !important;
    margin: 0px !important;
    border: 2px solid $second-color !important;
}
span.wpcf7-form-control-wrap{
	display: inline-block;
}
div.wpcf7-mail-sent-ok{
	display: block;
    float: none;
    clear: both;
    border: 0px solid #fff !important;
    font-size: 32px;
    line-height: 48px;
    color: $prime-color;
    padding: 0px !important;
    margin: 0px !important;
}



@import '_sticky-menu.scss';
@import '_slideshow.scss';
@import '_schedule-form.scss';
@import '_breakpoints.scss';
