@import '_breakpoints.scss';
@import '_hamburgers.scss';

.stick-logo{
    display: none;
}

// Sticky active
html.sticky-hide .menu {
    display: none !important;
}

html.sticky-active .menu {
    width: 100%;
    left: 0;
    background: rgba($prime-color, .9);
    z-index: 3;
    top: 0;
    display: block;
    max-height: 108px;
    position: fixed;
    .stick-logo{
        display: inline-block;
        float: left;
        width: 250px;
        height: 45px;
        background: url(../imgs/sticky-logo.png) no-repeat top;
        margin-top: 30px;
        margin-left: 30px;
    }
    .cta {
        display: block;
        margin: 28px 140px 0 0;
    }

    > .a-head {
        display: none;
        position: absolute;
        right: 0;
    }

    > .a-head-small {
        display: block;
        > span {
            color: #fff;
            text-decoration: none;
            font-family: $sansB;
            text-transform: uppercase;
            font-weight: $fw-light;
        }
    }

    > .dropdown-menu {
        visibility: visible;
        display: inline-block;
    }
    .cta {
        transition: all 0.5s;
        a{
            background: rgba(#fff, 1);
            color: $prime-color;
            &:last-of-type{
                color: #fff;
                background: $second-color;
            }
            &:hover{
                color: #fff;
                background: $prime-color;
            }
        }
    }
}

html.sticky-active .logo {
    visibility: hidden;
}

// Sticky inactive
.menu {
    z-index: 1000;
    pointer-events: none;
    * {pointer-events: auto;}

    // Dropdown/flyout menu
    > .dropdown-menu {
        visibility: hidden;
        display: none;
        position: relative;
        @include small-menu {visibility: visible;}
        transition: transform .2s, opacity 0.001s .2s;
        transform: translateX(105%);
        opacity: 0;
        float: right;
        background: rgba($prime-color, .9);
        margin-top: 27px;
        overflow: auto;
        height: auto;
        a {
            display: block;
            padding: 18px 4.5em;
            color: #fff;
            text-decoration: none;
            font-family: $sansB;
            text-transform: uppercase;
            white-space: nowrap;
            font-weight: $fw-light;
            text-align: center;
            &:hover{
                background: rgba(#fff, .26);
            }
        }
    }
    &.is-active > .dropdown-menu {
        transition: transform .2s, opacity 0.001s 0s;
        transform: translateX(0);
        opacity: 1;
    }
    > .a-head-small {
        display: none;
        position: absolute;
        top: 28px;
        right: 12px;

        > span {
            line-height: 53px;
        }

    }

    // Active/inactive text
    > .a-head-small {
        > span {
            padding-left: 9.9375px;
            vertical-align: top;
            color: #fff;
            font-weight: $fw-light;
            font-family: $sansB;
            letter-spacing: .1em;
            &:before {
                content: 'MENU';
                display: inline;
                vertical-align: top;
            }
        }
    }
    &.is-active > .a-head-small {
        > span {
            &:before {
                content: 'BACK';
            }
        }
    }
}
// sticky in active internal
html:not(.sticky-active) .internal .menu{
    padding: 0px !important;
}
@media screen and (max-width: 1312px) {
    html:not(.sticky-active) .internal .menu{
        position: absolute;
        top: 0;
    }
}

// Breakpoint for when main menu doesn't fit
@include small-menu {
    .menu {
        position: fixed;
        width: 100%;

        // Hide desktop menu
        > .a-head {
            display: none;
        }
        // Show mobile menu link
        > .a-head-small {
            display: block;
            position: relative;
            > span {
                display: inline-block;
                text-align: right;
            }
        }

        html:not(.sticky-active) & > .dropdown-menu {
            display: inline-block;
            margin: 80px 0 0 0;
        }

        html.sticky-active & > .a-head-small {
            position: absolute;
        }

        .cta {
            display: none;
        }


    }
}

@media screen and (max-width: 960px) {
    html.sticky-active .menu .stick-logo{
        display: none;
    }
}

@media screen and (max-width: 645px) {
    html.sticky-active .menu{
        max-height: 160px;
        text-align: center;
        background: rgba($prime-color, 0.9) url(../imgs/m-icon.png) no-repeat 15px 30px;
        .stick-logo {
            display: block;
            background: none;
            width: 90px;
            height: 50px;
            left: 0;
            margin: 30px 0 0 -35px;
        }
        > .dropdown-menu{
            margin-top: 25px;
        }
        .cta{
            display: block;
            margin: 92px -50px 0 -50px;
            text-align: center;
            a{
                font-size: 12px;
                padding: 14px 20px;
            }
        }
    }
    html:not(.sticky-active) .menu > .dropdown-menu {
        left: auto !important;
        right: 0px !important;
    }
}

@media screen and (max-width: 327px) {
    html.sticky-active .menu{
        > .dropdown-menu{
            margin-top: 28px;
        }
        .cta{
            display: block;
            margin: 92px -50px 0 -50px;
            text-align: center;
            a{
                font-size: 10px;
                padding: 14px 20px;
            }
        }
    }
}